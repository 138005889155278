//new instance axios
import { login } from './auth/login';
import { getProfile } from './auth/getProfile';
import { qualityferMortgage } from "./qualityfer/qualityfer-mortgage";
import { qualityferSubrogates } from "./qualityfer/qualityfer-subrogates";
import { multisearch } from "./operations/multisearch";
import { getMortgageData } from "./operations/getMortgageData";
import { scoringSimulateLtv } from "./scoring/scoringSimulateLtv"
import env from "../environment";
import axios from 'axios';

export const capi = axios.create({
    baseURL: env.api.url
});

export const api = {
    auth: {
        login, getProfile
    },
    qualityfer: {
        qualityferMortgage,
        qualityferSubrogates

    },
    operations: {
        multisearch,
        getMortgageData
    },
    scoring: {
        scoringSimulateLtv
    }
}
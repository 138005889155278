import { api } from "../../../Api";
import { ScoringSimulateLtvDTOin } from "../../../Api/scoring/DTOs/scoringSimulateLtvDTOin";
import { ScoringSimulateLtvDTOout } from "../../../Api/scoring/DTOs/scoringSimulateLtvDTOout";
import {
  SCORING_SIMULATE_LTV_LOADING,
  SCORING_SIMULATE_LTV_SUCCESS,
} from "../constants";

export const scoringSimulateLtvRedux =
  (scoringSimulateLtvDTOin: ScoringSimulateLtvDTOin) =>
  async (dispatch: any) => {
    dispatch({ type: SCORING_SIMULATE_LTV_LOADING });

    return api.scoring
      .scoringSimulateLtv(scoringSimulateLtvDTOin)
      .then((res: ScoringSimulateLtvDTOout) => {
        if (res.data.success) {
          dispatch({
            type: SCORING_SIMULATE_LTV_SUCCESS,
            payload: res.data,
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

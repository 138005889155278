import { MULTISEARCH_OPERATIONS_ERROR, MULTISEARCH_OPERATIONS_LOADING, MULTISEARCH_OPERATIONS_SUCCESS } from "./constants";
import { Operation } from "./types/Operations";

export type MultisearchState = {
    operations: Array<Operation>;
    loading: boolean;
    error: string;
}

export default (state = {}, action: any) => {
    switch (action.type) {
        case MULTISEARCH_OPERATIONS_LOADING:
            return {
                loading: true,
            };
        case MULTISEARCH_OPERATIONS_ERROR:
            return {
                error: action.payload.message,
                loading: false,
            };
        case MULTISEARCH_OPERATIONS_SUCCESS:
            return {
                operations: action.payload,
                loading: false,
                error: undefined,
            };
        default:
            return state;
    }
}


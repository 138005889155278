import { GET_PROFILE, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from "./constants";

export type AuthState = {
    isLogged: boolean,
    token: string,
    profile: any,
    expiresAt: string,
    roles: string[],
    error: string,
}

export default (state = {}, action: any) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                token: action.payload.data.token,
                error: '',
                isLogged: true,
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                error: action.payload.message
            };
        case GET_PROFILE:
            return {
                ...state,
                profile: action.payload.data
            };
        case LOGOUT:
            return {};
        default:
            return state;
    }
}
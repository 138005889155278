import { capi } from '../index';
import { LoginDTOout } from './DTOs/loginDTOout';
import { LoginDTOin } from './DTOs/loginDTOin';

export const login = (login: LoginDTOin): Promise<LoginDTOout> => {
    return capi.post('/v1/auth/login', {
        username: login.username,
        password: login.password
    });
}

import { SCORING_RESET_LTV, SCORING_SIMULATE_LTV_ERROR, SCORING_SIMULATE_LTV_LOADING, SCORING_SIMULATE_LTV_SUCCESS } from "./constants";
import { SimulateLTV } from "./types/SimulateLTV";

export type SimulateLTVState = {
    simulations: SimulateLTV[];
    loading: boolean;
    error: string;
}

export default (state = {} , action: any) => {
    switch (action.type) {
        case SCORING_SIMULATE_LTV_LOADING:
            return {
                loading: true,
            };
        case SCORING_SIMULATE_LTV_ERROR:
            return {
                error: action.payload.message,
                loading: false,
            };
        case SCORING_SIMULATE_LTV_SUCCESS:
            return {
                simulations: action.payload.data,
                loading: false,
                error: undefined,
            };
        case SCORING_RESET_LTV:
            return {
                simulations: undefined,
                loading: false,
                error: undefined,
            };
        default:
            return state;
    }
}


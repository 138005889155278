import { Alert, Button, Card, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import React, { RefObject, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { qualityferViabilitySubrogatesRedux } from "../Store/qualityfer/actions/qualityferViabilitySubrogatesRedux";
import { qualityferViabilityMortgagesRedux } from "../Store/qualityfer/actions/qualityferViabilityMortgagesRedux";
import { RootState } from "../Store";
import MultiSearchOperations from "../Components/operations/multisearchOperations";
import { Operation } from "../Store/multisearchOperations/types/Operations";
import { qualityferGetDataMortgageRedux } from "../Store/qualityfer/actions/qualityferGetDataMortgageRedux";
import { qualityferDeselectDataMortgageRedux } from "../Store/qualityfer/actions/qualityferDeselectDataMortgageRedux";
import { provinceRegion } from "../Consts/provinces";
import { MaskDate } from "../Components/InputMask/masks/MaskDate";
import moment from "moment";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { scoringSimulateLtvRedux } from "../Store/scoring/actions/scoringSimulateLtvRedux";
import { scoringResetLtvRedux } from "../Store/scoring/actions/scoringResetLtvRedux";
import { HomeOutlined, SwapOutlined } from "@ant-design/icons";
import _ from "lodash";

const FormQualityFer = () => {
    const [form] = Form.useForm();
    const [type, setType] = useState("");
    const [typeModal, setTypeModal] = useState(false);
    const [selectTypeModal, setSelectTypeModal] = useState('');
    const dispatch = useDispatch();
    const viable = useSelector((state: RootState) => state.qualityfer.viable);
    const loadingViability = useSelector((state: RootState) => state.qualityfer.loadingViability);
    const error = useSelector((state: RootState) => state.qualityfer.error);
    const maxLtvGibobs = 0.90;
    const maxLtvSabadell = 0.85;
    const [maxLtv, setMaxLtv] = useState(maxLtvGibobs);
    const operation = useSelector((state: RootState) => state.qualityfer.mortgageSelected)
    const ltv = useSelector((state: RootState) => state.simulateLtv);
    const profile = useSelector((state: RootState) => state.auth.profile);
    const provinces = Object.keys(provinceRegion);

    const operationAfterViability = useSelector((state: RootState) => state.qualityfer.operation);
    const [isSabadellOperation, setIsSabadellOperation] = useState(false)
    const onFinish = (values: any) => {

        // añadir el agent id de la session a la peticion
        if (type === "NUEVA_HIPOTECA") {
            values.agent = profile.id;
            values.agentName = profile.name;
            qualityferViabilityMortgagesRedux(values)(dispatch);
        } else {
            const dateTemp = values.dateSign.split("-").map((a: string) => { return Number.parseInt(a) });
            values.dateSign = `${dateTemp[2]}-${dateTemp[1]}-${dateTemp[0]}`
            values.agent = profile.id;
            values.agentName = profile.name;
            qualityferViabilitySubrogatesRedux(values)(dispatch);
        }
    }
    const onSelectedOperation = async (value: Operation | undefined) => {
        if (value !== undefined) {

            const data = await qualityferGetDataMortgageRedux({ mortgage: value.id })(dispatch);
            if (data.success) {
                form.setFieldsValue({
                    clientName: data.data.owner1.name,
                    clientPhone: data.data.owner1.phone,
                    operationId: value.id,
                });
                let type_value = "";

                if (value.subtype !== undefined) {
                    type_value = (value.subtype == 'mortgage' ? 'NUEVA_HIPOTECA' : 'SUBROGACIÓN');
                } else {
                    type_value = (value.operationType == 'mortgage' ? 'NUEVA_HIPOTECA' : 'SUBROGACIÓN');
                }

                setType(type_value);

                if (value.realestate === 'Banco Sabadell SA') {
                    setIsSabadellOperation(true);
                    setMaxLtv(maxLtvSabadell);
                } else {
                    setIsSabadellOperation(false);
                    setMaxLtv(maxLtvGibobs);
                }
                //if (type_value === 'NUEVA_HIPOTECA') {

                form.setFieldsValue({
                    familyIncomeMonthly: data.data.owner1.netIncomes,
                    loansMonthly: data.data.owner1.loanPayment,
                    canHavePaidOwnedHouses: data.data.owner1.canHavePaidOwnedHouses,
                    professionalActivity: data.data.owner1.activity,
                    birthdate: data.data.owner1.age,
                })

                if (data.data.mortgage !== undefined) {
                    form.setFieldsValue({
                        isChosenHouse: data.data.mortgage.houseState,
                        timeToBuy: data.data.mortgage.purchaseForecast,
                        priceHouse: data.data.mortgage.maxBudget,
                        savingsProvided: data.data.mortgage.savings,
                        canHaveGuarantors: data.data.mortgage.canHaveGuarantors,
                        province: data.data.mortgage.province,
                    })
                }

                //} else if (type_value === 'SUBROGACIÓN') {
                if (data.data.subrogation !== undefined) {
                    console.log(value.realestate);
                    if (value.realestate === 'Banco Sabadell SA') {
                        form.setFieldsValue({
                            savingMoneyByCC: (_.isNil(data.data.qualitySabadell.savingMoneyByCC)) ? data.data.qualitySabadell.savingMoney : data.data.qualitySabadell.savingMoneyByCC,
                        })
                    }

                    form.setFieldsValue({
                        amountToFinance: data.data.subrogation.amount,
                        reason: data.data.subrogation.reason,
                        dateSign: moment(data.data.subrogation.dateSign).format("DD-MM-YYYY")
                    });
                }
                //}
            }
        } else {

            /*form.setFieldsValue({
                operationId: '', clientName: '', clientPhone: '',
                paidOwnedHouses: '', professionalActivity: '', guarantors: '',
                priceHouse: '', savingsProvided: '', familyIncomeMonthly: '',
                loansMonthly: '', timeToBuy: '', amountToFinance: '', reason: '',
                dateSign: ''
            });*/
            form.resetFields();
            setType('NUEVA_HIPOTECA');
            qualityferDeselectDataMortgageRedux()(dispatch);
            scoringResetLtvRedux()(dispatch);
        }


    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed: ", errorInfo);
    }
    const onTypeOperationChange = async (value: string) => {
        setType(value);
    }

    const onHouseChosenChange = (value: string) => {
        //form.setFieldsValue({ isChosenHouse: (value === "1" ? true : false) });
        form.setFieldsValue({ isChosenHouse: value });
    }
    const onTimeToBuyChange = (value: string) => {
        //form.setFieldsValue({ timeToBuy: Number.parseInt(value) });
        form.setFieldsValue({ timeToBuy: value });
    }
    const onReasonChanges = (value: string) => {
        form.setFieldsValue({ reason: value });
    }
    const onProfessionalActivityChanges = (value: string) => {
        form.setFieldsValue({ professionalActivity: value });
        checkLTV();
    }

    const onProvinceChange = (value: string) => {
        form.setFieldsValue({ province: value });
        checkLTV();
    }

    const onPriceHouseChange = (value: number) => {
        form.setFieldsValue({ priceHouse: value });
        checkLTV();
    }

    const onSavingsProvidedChange = (value: number) => {
        form.setFieldsValue({ savingsProvidedChange: value });
        checkLTV();
    }

    const checkLTV = () => {
        if (operation != null && type === "NUEVA_HIPOTECA" && form.getFieldValue('professionalActivity') !== (undefined || null || '' || 'FUNCTIONARY')) {
            const priceHouse = form.getFieldValue("priceHouse");
            const savings = form.getFieldValue("savingsProvided");
            const province = form.getFieldValue("province")
            scoringSimulateLtvRedux({
                maxBudget: priceHouse,
                savings: savings,
                province: province,
                propertyType: operation.mortgage.propertyType,
                rateTypes: 'both',
                term: operation.mortgage.viabilityData.operation.quotes
            })(dispatch)
        } else {
            scoringResetLtvRedux()(dispatch);
        }
    }

    return (
        <Card style={{ margin: 'auto', marginTop: 40, marginBottom: 40, marginLeft: 40, marginRight: 40 }} bordered={true}>
            <Form
                name="formQualityFer"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                form={form}
                initialValues={
                    {
                        isChosenHouse: "",
                        reason: "",
                        loansMonthly: 0,
                        professionalActivity: "",
                        timeToBuy: "",
                        province: "",
                        canHaveGuarantors: false,
                        canHavePaidOwnedHouses: false,
                        birthdate: 0,
                    }
                }
            >
                <Row gutter={16} justify='center'>
                    <Col span={12}>
                        <Form.Item label="Buscar operación" rules={[{ required: true, message: "Introduzca la operación" }]} name="operationId">
                            <MultiSearchOperations onSelectedOperation={onSelectedOperation} />
                        </Form.Item>
                    </Col>
                </Row>
                {operation != null && operation !== undefined && form.getFieldValue('operationId') &&
                    <div className="wrapperForm">
                        <Row gutter={16} align="middle" style={{ margin: 10 }}>
                            <Col span={6}>
                                <div style={{ display: 'flex' }}>
                                    {type === "NUEVA_HIPOTECA" &&
                                        <div>
                                            <h2><HomeOutlined /> Hipoteca</h2>
                                        </div>
                                    }
                                    {type === "SUBROGACIÓN" &&
                                        <div>
                                            <h2><SwapOutlined /> Subrogación</h2>
                                        </div>
                                    }
                                    <div>
                                        <Button style={{ marginLeft: 10 }} type="primary" onClick={() => { setTypeModal(true) }}>
                                            <b>Convertir operación</b>
                                        </Button>
                                        <Modal width={480} title="Convertir operación" visible={typeModal} onCancel={() => setTypeModal(false)} footer={[
                                            <Button onClick={() => setTypeModal(false)} style={{ height: 40, width: '46%' }} key="back">
                                                Cancelar
                                            </Button>,

                                            <Button onClick={() => { onTypeOperationChange(selectTypeModal); setTypeModal(false) }} style={{ height: 40, width: '46%', marginLeft: 32 }} key="submit" type="primary">
                                                Enviar
                                            </Button>

                                        ]} >
                                            Elige el tipo de operación al que deseas cambiar esta operación

                                            <Select defaultValue={type} style={{ width: '100%' }} onChange={(val) => setSelectTypeModal(val)}>
                                                <Select.Option value="NUEVA_HIPOTECA">Nueva hipoteca</Select.Option>
                                                <Select.Option value="SUBROGACIÓN">Subrogación</Select.Option>
                                            </Select>
                                        </Modal>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item label="Nombre y Apellidos" rules={[{ required: true, message: "Introduzca el nombre" }]} name="clientName">
                                    <Input type="text" placeholder="Nombre" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Télefono" rules={[{ required: true, message: "Introduzca el número de telefono" }]} name="clientPhone">
                                    <Input type="number" placeholder="Teléfono" />
                                </Form.Item>
                            </Col>
                            {
                                type === "NUEVA_HIPOTECA" &&
                                <Col span={6}>
                                    <Form.Item label="Edad" rules={[{ required: true, message: "Introduzca la edad" }]} name="birthdate">
                                        <Input type="number" placeholder="Edad" />
                                    </Form.Item>
                                </Col>
                            }
                            {
                                type === "NUEVA_HIPOTECA" &&
                                <Col span={6}>
                                    <Form.Item label="¿Cuándo tienes pensado comprar?" rules={[{ required: true, message: "Introduzca cuando tiene pensado comprar" }]} name="timeToBuy">
                                        <Select style={{ width: '100%' }} onChange={onTimeToBuyChange}>
                                            <Select.Option value="lessThanSixMonths">Lo antes que pueda</Select.Option>
                                            <Select.Option value="withinSixOrTwelveMonths">Dentro de 6-12 meses</Select.Option>
                                            <Select.Option value="whitinOneOrTwoYears">Dentro de 1 o 2 años</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                        <div>
                            {type === "NUEVA_HIPOTECA" &&
                                <div>
                                    <Row gutter={16}>
                                        <Col span={6}>
                                            <Form.Item label="¿Tienes la vivienda elegida?" rules={[{ required: true, message: 'Selecciona si tiene la vivienda elegida' }]} name="isChosenHouse">
                                                <Select style={{ width: '100%' }} onChange={onHouseChosenChange}>
                                                    <Select.Option value="have_decide">Sí</Select.Option>
                                                    <Select.Option value="searching">No</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="Provincia" name={"province"} rules={[{ required: true, message: 'Selecciona la provicincia de la vivienda' }]}>
                                                <Select value={type} style={{ width: '100%' }} onChange={onProvinceChange}>
                                                    {provinces.map((province: string) => (
                                                        <Select.Option key={province} value={province}>{province}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="Precio de la casa" rules={[{ required: true, message: "Introduzca el precio de la vivienda" }]} name="priceHouse">
                                                <InputNumber style={{ width: '100%' }} placeholder="Precio de la casa" onChange={onPriceHouseChange} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="Ahorros aportados" rules={[{ required: true, message: "Introduzca los ahorros aportados" }]} name="savingsProvided">
                                                <InputNumber style={{ width: '100%' }} placeholder="Ahorros aportados" onChange={onSavingsProvidedChange} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={6}>
                                            <Form.Item label="Actividad profesional" rules={[{ required: true, message: "Introduzca la actividad profesional" }]} name="professionalActivity">
                                                <Select value={type} style={{ width: '100%' }} onChange={onProfessionalActivityChanges}>
                                                    <Select.Option value="EMPLOYEE_FIXED">Asalariado fijo</Select.Option>
                                                    <Select.Option value="EMPLOYEE_TEMPORAL">Asalariado temporal</Select.Option>
                                                    <Select.Option value="FREELANCER">Autónomo</Select.Option>
                                                    <Select.Option value="PENSIONER">Pensionista</Select.Option>
                                                    <Select.Option value="BUSINESS_OWNER">Empresario</Select.Option>
                                                    <Select.Option value="FUNCTIONARY">Funcionario</Select.Option>
                                                    <Select.Option value="RENTIER">Rentista</Select.Option>
                                                    <Select.Option value="UNEMPLOYED">Desempleado</Select.Option>
                                                    <Select.Option value="FUNCTIONARY_INTERIM">Funcionario interino</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={6}>
                                            <Form.Item label="Ingresos de unidad familiar (€/mes prorrateados)" rules={[{ required: true, message: "Introduzca los ingresos familiares" }]} name="familyIncomeMonthly">
                                                <InputNumber style={{ width: '100%' }} placeholder="Ingresos de unidad familiar (mensual)" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="Si préstamos, (€/mes)" rules={[{ required: true, message: "Introduzca préstamos" }]} name="loansMonthly">
                                                <InputNumber style={{ width: '100%' }} placeholder="Si préstamos, importe mensual" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            {!ltv.loading && ltv.simulations !== undefined && (ltv.simulations[0].ltv > maxLtv || ltv.simulations[1].ltv > maxLtv) &&
                                                <div>
                                                    <Row gutter={16}>
                                                        <Col span={12}>
                                                            <div style={{ marginBottom: 12 }}>
                                                                Preguntas de rescate
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={[16, 8]} >
                                                        <Col span={16}>
                                                            <Form.Item name="canHaveGuarantors" valuePropName="checked">
                                                                <Checkbox>Tengo alguien que pueda avalarme</Checkbox>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={16}>
                                                            <Form.Item name="canHavePaidOwnedHouses" valuePropName="checked">
                                                                <Checkbox>Tengo una vivienda en propiedad ya pagada</Checkbox>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                    {operationAfterViability !== undefined &&
                                        <Row gutter={16} style={{ marginBottom: 10, marginTop: 10 }} align="middle" justify="center">
                                            <Col style={{ textAlign: 'center' }} span={6}>
                                                <span style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold', padding: 5, fontSize: 16 }}>
                                                    LTV: {(operationAfterViability.operationData.qualityfer !== undefined) ?
                                                        Number(operationAfterViability.operationData.qualityfer.ltv * 100).toFixed(0) :
                                                        (operationAfterViability.operationData.qualitySabadell !== undefined) ?
                                                            Number(operationAfterViability.operationData.qualitySabadell.ltv * 100).toFixed(0) : 0}%
                                                </span>
                                            </Col>
                                            <Col style={{ textAlign: 'center' }} span={6}>
                                                <span style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold', padding: 5, fontSize: 16 }}>
                                                    DTI: {(operationAfterViability.operationData.qualityfer !== undefined) ?
                                                        Number(operationAfterViability.operationData.qualityfer.dti * 100).toFixed(0) :
                                                        (operationAfterViability.operationData.qualitySabadell !== undefined) ?
                                                            Number(operationAfterViability.operationData.qualitySabadell.dti * 100).toFixed(0) : 0}%                                                </span>
                                            </Col>
                                            <Col style={{ textAlign: 'center' }} span={6}>
                                                <span style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold', padding: 5, fontSize: 16 }}>
                                                    Impuestos: {(operationAfterViability.operationData.mortgage.viabilityData.operation.taxes !== undefined) ? Number(operationAfterViability.operationData.mortgage.viabilityData.operation.taxes).toFixed(2) : 0}€
                                                </span>
                                            </Col>
                                            <Col style={{ textAlign: 'center' }} span={6}>
                                                <span style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold', padding: 5, fontSize: 16 }}>
                                                    Tasas: 2000€
                                                </span>
                                            </Col>

                                        </Row>
                                    }
                                </div>

                            }

                            {type === "SUBROGACIÓN" &&

                                <Row gutter={16}>
                                    <Col span={6}>
                                        <Form.Item label="¿Cuál es el motivo?" name="reason" rules={[{ required: true, message: "Introduzca el motivo de la subrogación" }]}>

                                            <Select style={{ width: '100%' }} onChange={onReasonChanges}>
                                                <Select.Option value="improveMortgage">Mejora de hipoteca</Select.Option>
                                                <Select.Option value="increaseCapitalRenovations">Ampliar capital para reformas</Select.Option>
                                                <Select.Option value="increaseCapitalLiquidity">Ampliar capital para liquidez</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="Cantidad a financiar" rules={[{ required: true, message: "Introduzca la cantidad a financiar" }]} name='amountToFinance'>
                                            <InputNumber style={{ width: '100%' }} type="number" placeholder="Cantidad a financiar" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="Fecha de firma" rules={[{ required: true, message: "Introduzca la fecha a firmar" }]} name='dateSign'>
                                            <MaskDate value={form.getFieldValue("dateSign")} onChange={(e) => { form.setFieldsValue({ dateSign: e }) }} />
                                        </Form.Item>
                                    </Col>
                                    {
                                        isSabadellOperation &&
                                        <Col span={6}>
                                            <Form.Item label="¿Existe la opción de que el cliente ahorre con una subrogación?" rules={[{ required: true, message: "Introduzca la fecha a firmar" }]} valuePropName="checked" name='savingMoneyByCC'>
                                                <Checkbox onChange={(e) => { form.setFieldsValue({ savingMoneyByCC: e.target.checked }) }} />
                                            </Form.Item>
                                        </Col>
                                    }
                                </Row>
                            }
                            <Row justify="center">
                                <Col>
                                    <Form.Item>
                                        <Button type="primary" loading={loadingViability} className="button-primari-gibobs" htmlType="submit" block>
                                            Comprobar viabilidad
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} justify="center">
                                <Col span={12}>
                                    {viable === true &&
                                        <Alert message="La operación es viable" type="success" />
                                    }
                                    {viable === false &&
                                        <Alert message="La operación no es viable" type="error" />
                                    }

                                    {error &&
                                        <Alert message={error} type="error" />
                                    }
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
            </Form >
        </Card >
    )
}

export default FormQualityFer;
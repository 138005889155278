import { api } from "../../../Api";
import { MultisearchDTOin } from "../../../Api/operations/DTOs/multisearchDTOin";
import { MultisearchDTOout } from "../../../Api/operations/DTOs/multisearchDTOout";
import { MULTISEARCH_OPERATIONS_LOADING, MULTISEARCH_OPERATIONS_SUCCESS } from "../constants";

export const multisearchOperationsGetRedux = (multisearchDTOin: MultisearchDTOin) => async (dispatch: any) => {
    dispatch({ type: MULTISEARCH_OPERATIONS_LOADING });

    return api.operations.multisearch(multisearchDTOin).then((res: MultisearchDTOout) => {
        dispatch({
            type: MULTISEARCH_OPERATIONS_SUCCESS,
            payload: res.data

        });
    }).catch((err: any) => {
        console.log(err);
    });
}
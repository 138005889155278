import { AutoComplete, Button, Col, Row } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Store";
import { multisearchOperationsGetRedux } from "../../Store/multisearchOperations/actions/multisearchOperationsGetRedux";
import { Operation } from "../../Store/multisearchOperations/types/Operations";
import { DeleteFilled } from "@ant-design/icons"

interface Props {
    onSelectedOperation: (value: Operation | undefined) => void;
}

const MultiSearchOperations = (props: Props) => {
    const [selected, setSelected] = useState<boolean>(false);
    const [valueSearched, setValueSearched] = useState<string>("");

    const operations: Array<Operation> = useSelector((state: RootState) => (state.multisearchOperations.operations) ? state.multisearchOperations.operations : []);
    const dispatch = useDispatch();
    const onSearch = (value: string) => {
        if (value.length > 3) {
            multisearchOperationsGetRedux({ name: value })(dispatch);
        }
    }

    const onSelect = (value: string) => {
        setSelected(true);
        const operation: Operation | undefined = operations.find(o => o.name === value);
        props.onSelectedOperation(operation);
    }
    const onClickSearch = () => {
        setSelected(true);
        const operation: Operation | undefined = operations.find(o => o.name === valueSearched);
        props.onSelectedOperation(operation);
    }
    const onRemoveSelected = () => {
        setSelected(false);
        props.onSelectedOperation(undefined);
    }


    return (
        <Row justify="space-between" align="middle" >
            <Col span={(18)} >
                <AutoComplete disabled={selected} options={(operations.length > 0) ? operations.map((oper: Operation) => {
                    return {
                        value: oper.name,
                        label: oper.name
                    }
                }) : []} onSearch={onSearch} onChange={(value: string) => { setValueSearched(value) }} onSelect={onSelect} />
            </Col>
            <Col span={5}>
                {selected && <Button onClick={onRemoveSelected} type="primary" style={{ width: '100%' }}>Nueva búsqueda</Button>}
                {!selected && <Button onClick={onClickSearch} type="primary" style={{ width: '100%' }}>Buscar operación</Button>}
            </Col>

        </Row>
    );

}

export default MultiSearchOperations;
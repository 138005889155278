import { GET_DATA_MORTGAGES_DESELECTED } from "../constants";
import { qualityferViabilityResetRedux } from "./qualityferViabilityResetRedux";

export const qualityferDeselectDataMortgageRedux = () => async (dispatch: any) => {

    dispatch({
        type: GET_DATA_MORTGAGES_DESELECTED
    });

    dispatch(qualityferViabilityResetRedux());
}
import { Card } from "antd";

const Header = () => {

    return (
        <Card bodyStyle={{ padding: 12 }}>
            <div style={{ textAlign: "center", }}>
                <img
                    width={88}
                    height={40}
                    src={
                        "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/logos/logo-gmail.png"
                    }

                    alt="logo"
                ></img>
            </div>
        </Card>
    )

}

export default Header;
import { GET_DATA_MORTGAGES_DESELECTED, GET_DATA_MORTGAGES_ERROR, GET_DATA_MORTGAGES_LOADING, GET_DATA_MORTGAGES_SUCCESS, QUALITYFER_VIABILITY_ERROR, QUALITYFER_VIABILITY_LOADING, QUALITYFER_VIABILITY_MORTGAGES, QUALITYFER_VIABILITY_RESET, QUALITYFER_VIABILITY_SUBROGATES } from "./constants";

export type QualityferState = {
    operation: any;
    viable: boolean;
    error: string;
    mortgageSelected: any;
    loadingMortgages: boolean;
    loadingViability: boolean;
}


export default (state = {}, action: any) => {
    switch (action.type) {
        case GET_DATA_MORTGAGES_SUCCESS:
            return {
                ...state,
                mortgageSelected: action.payload.data,
                loadingMortgages: false
            };
        case GET_DATA_MORTGAGES_DESELECTED:
            return {
                ...state,
                mortgageSelected: null,
                loadingMortgages: false
            };
        case GET_DATA_MORTGAGES_LOADING:
            return {
                ...state,
                loadingMortgages: true
            };
        case GET_DATA_MORTGAGES_ERROR:
            return {
                ...state,
                loadingMortgages: false,
                error: action.payload.message
            };
        case QUALITYFER_VIABILITY_LOADING:
            return {
                ...state,
                loadingViability: true
            };
        case QUALITYFER_VIABILITY_MORTGAGES:
            return {
                ...state,
                viable: action.payload.data.viable,
                operation: action.payload.data.operation,
                loadingViability: false,
                error: undefined
            };
        case QUALITYFER_VIABILITY_SUBROGATES:
            return {
                ...state,
                viable: action.payload.data.viable,
                loadingViability: false,
                error: undefined
            };
        case QUALITYFER_VIABILITY_RESET:
            return {
                ...state,
                viable: undefined,
                operation: undefined,
                loadingViability: false,
                error: undefined
            };
        case QUALITYFER_VIABILITY_ERROR:
            return {
                ...state,
                error: action.payload.message,
                loadingViability: false,
                viable: undefined,
                operation: undefined
            };
        default:
            return state;
    }
}
import { Input } from 'antd';
import { useEffect, useState } from 'react';
import { Rifm } from 'rifm';

type MaskDateProps = {
    value: string;
    onChange?: (value: string) => void;
    style?: React.CSSProperties;
    defaultValue?: string;
    placeholder?: string;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;

}
export const MaskDate = (props: MaskDateProps) => {
    const [masked, setMasked] = useState(props.value);
    const [firstLoad, setFirstLoad] = useState(true);

    const parseDigits = (string: string) => !string ? "" : (string.match(/\d+/g) || []).join('');

    useEffect(() => {
        /*if (props.value) {
            setMasked(props.value);
        }*/
        if (firstLoad) {
            setFirstLoad(false);
            return;
        }

        if (!masked) {
            setMasked("");
            return;
        }

        if (masked.match(/^[0-9]{2}-[0-9]{2}-[0-9]{4}$/)) {
            //console.log('si funciona')
            if (props.onChange) {
                props.onChange(masked)
            }
        } else {
            //console.log('no funciona', masked)
            if (props.onChange) {
                props.onChange('')
            }
        }

    }, [masked])

    const addMask = (string: string) => {

        const digits = parseDigits(string);
        const days = digits.slice(0, 2).padEnd(2, '_');
        const months = digits.slice(2, 4).padEnd(2, '_');
        const years = digits.slice(4, 8).padEnd(4, '_');
        return `${days}-${months}-${years}`;
    };


    const formatDate = (string: string) => {
        const digits = parseDigits(string);
        const chars = digits.split('');
        return chars
            .reduce(
                (r, v, index) => (index === 2 || index === 4 ? `${r}-${v}` : `${r}${v}`),
                ''
            )
            .substr(0, 10);
    };

    return (<Rifm
        value={masked}
        onChange={(value) => { console.log(value); setMasked(value) }}
        format={formatDate}
        replace={addMask}
    >
        {({ value, onChange }) => (
            <Input
                value={value}
                style={props.style}
                placeholder={props.placeholder}
                onChange={(e) => {
                    onChange(e)
                }}
                onBlur={props.onBlur}

            />
        )}
    </Rifm >)
}
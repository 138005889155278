import { capi } from "../../Api";
import { getProfileRedux } from "../auth/actions/getProfileRedux";
import { LOGIN_SUCCESS } from "../auth/constants";
import { LOADED } from "./constants";

export const initialLoad = () => async (dispatch: any) => {
    const token = localStorage.getItem("token");
    if (token) {
        const expiresAt = localStorage.getItem("expiresAt");
        if (expiresAt) {
            const now = new Date().getTime();
            if (now < Number.parseInt(expiresAt)) {
                capi.defaults.headers.common["Authorization"] = `Bearer ${token}`;

                dispatch(getProfileRedux());

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: {
                        data: {
                            token,
                            expiresAt,
                        }
                    }
                });
            } else {
                console.log("token expired");
                localStorage.removeItem("token");
                localStorage.removeItem("expiresAt");
                capi.defaults.headers.common["Authorization"] = "";
            }
        }
    }
    dispatch({
        type: LOADED
    })
}
import { LOADED } from "./constants";

export type InitialState = {
    isLoaded: boolean;
}

export default (state = {}, action: any) => {
    switch (action.type) {
        case LOADED:
            return {
                ...state,
                isLoaded: true,
            };
        default:
            return state;
    }
}
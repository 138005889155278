
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../Store";
type Props = {
    children: JSX.Element,
};
const ProtectedRouter = ({ children }: Props) => {
    const isLogged = useSelector((state: RootState) => state.auth.isLogged);
    const navigator = useNavigate();
    useEffect(() => {
        if (!isLogged) {
            navigator("/login");
        }

    });
    return children;
}

export default ProtectedRouter;


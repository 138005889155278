import { api } from "../../../Api";
import { QualityferDTOout } from "../../../Api/qualityfer/DTOs/qualityferDTOout";
import { QualityferSubrogatesDTOin } from "../../../Api/qualityfer/DTOs/qualityferSubrogatesDTOin";
import { QUALITYFER_VIABILITY_ERROR, QUALITYFER_VIABILITY_LOADING, QUALITYFER_VIABILITY_SUBROGATES } from "../constants";

export const qualityferViabilitySubrogatesRedux = (qualityferSubrogatesDTOin: QualityferSubrogatesDTOin) => async (dispatch: any) => {
    dispatch({
        type: QUALITYFER_VIABILITY_LOADING,
    });
    return api.qualityfer.qualityferSubrogates(qualityferSubrogatesDTOin).then((res: QualityferDTOout) => {
        if (res.data.success) {
            dispatch({
                type: QUALITYFER_VIABILITY_SUBROGATES,
                payload: res.data
            });
        } else {
            dispatch({
                type: QUALITYFER_VIABILITY_ERROR,
                payload: res.data
            });
        }
        return res.data;
    });
}
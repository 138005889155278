import { Button, Input, Form, Alert, Card } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../Store';
import { loginRedux } from '../Store/auth/actions/loginRedux';


const Login = () => {
    const dispatch = useDispatch();
    const navigator = useNavigate();

    const error = useSelector((state: RootState) => state.auth.error);
    const isLogged = useSelector((state: RootState) => state.auth.isLogged);

    useEffect(() => {
        if (isLogged) {
            navigator("/qualityfer");
        }
    }, [dispatch]);

    const responseLogin = (username: string, password: string) => {
        loginRedux(username, password)(dispatch).then((res: any) => {
            if (res.success) {
                navigator("/qualityfer");
            }
        });
    }

    const onFinish = (values: any) => {
        responseLogin(values.username, values.password);
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed: ', errorInfo);
    }



    return (
        <Card style={{ margin: 'auto', marginTop: 40, marginBottom: 40, maxWidth: 600 }} bordered={true}>
            <Form
                name="login"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: "Introduzca su email" }]}
                >
                    <Input
                        type="email"
                        style={{
                            border: "1px solid #C0DAEE",
                            borderRadius: "4px",
                        }}
                        placeholder="Usuario"
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Por favor, introduzca su contraseña",
                        },
                    ]}
                >
                    <Input.Password
                        style={{
                            border: "1px solid #C0DAEE",
                            borderRadius: "4px",
                        }}
                        placeholder="Contraseña"
                    />
                </Form.Item>
                <Form.Item>
                    <Button className="button-primari-gibobs" type="primary" htmlType="submit" block>
                        Acceder
                    </Button>
                </Form.Item>

                {error && <Alert message={error} type="error" />}
            </Form>
        </Card>
    );
}

export default Login;
import { api } from "../../../Api"
import { QualityferMortgageDTOin } from "../../../Api/qualityfer/DTOs/qualityferMortgageDTOin";
import { QualityferDTOout } from "../../../Api/qualityfer/DTOs/qualityferDTOout";
import { QUALITYFER_VIABILITY_ERROR, QUALITYFER_VIABILITY_LOADING, QUALITYFER_VIABILITY_MORTGAGES } from "../constants";

export const qualityferViabilityMortgagesRedux = (qualityferMortgageDTONin: QualityferMortgageDTOin) => async (dispatch: any) => {
    dispatch({
        type: QUALITYFER_VIABILITY_LOADING,
    });
    return api.qualityfer.qualityferMortgage(qualityferMortgageDTONin).then((res: QualityferDTOout) => {
        if (res.data.success) {
            dispatch({
                type: QUALITYFER_VIABILITY_MORTGAGES,
                payload: res.data
            });
        } else {
            dispatch({
                type: QUALITYFER_VIABILITY_ERROR,
                payload: res.data
            });
        }
        return res.data;
    });
}
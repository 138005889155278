import { api } from "../../../Api";
import { GetMortgageDataDTOin } from "../../../Api/operations/DTOs/getMortgageDataDTOin";
import { GetMortgageDataDTOout } from "../../../Api/operations/DTOs/getMortgageDataDTOout";
import { scoringSimulateLtvRedux } from "../../scoring/actions/scoringSimulateLtvRedux";
import { scoringResetLtvRedux } from "../../scoring/actions/scoringResetLtvRedux";
import { GET_DATA_MORTGAGES_ERROR, GET_DATA_MORTGAGES_LOADING, GET_DATA_MORTGAGES_SUCCESS } from "../constants";

export const qualityferGetDataMortgageRedux = (getMortgageDataDTOin: GetMortgageDataDTOin) => async (dispatch: any) => {
    //loading
    dispatch({
        type: GET_DATA_MORTGAGES_LOADING
    });
    //get data
    return api.operations.getMortgageData(getMortgageDataDTOin).then((res: GetMortgageDataDTOout) => {
        //success
        if (res.data.success) {
            dispatch({
                type: GET_DATA_MORTGAGES_SUCCESS,
                payload: res.data
            });
            if ((res.data.data.operation !== undefined && res.data.data.operation.subtype === "mortgage") || (res.data.data.operation === undefined && res.data.data.mortgage?.operationType === 'mortgage')) {

                if (res.data.data.mortgage) {

                    if (res.data.data.owner1.activity !== (undefined || null || '' || 'FUNCTIONARY')) {
                        dispatch(scoringSimulateLtvRedux({
                            maxBudget: res.data.data.mortgage?.maxBudget,
                            province: res.data.data.mortgage?.province,
                            rateTypes: 'both',
                            term: (res.data.data.mortgage.viabilityData !== undefined) ? res.data.data.mortgage.viabilityData.operation.quotes : 30,
                            propertyType: res.data.data.mortgage.propertyType,
                            savings: res.data.data.mortgage?.savings
                        }))
                    } else {
                        dispatch(scoringResetLtvRedux())
                    }
                }
            }
        } else {
            //error
            dispatch({
                type: GET_DATA_MORTGAGES_ERROR,
                payload: res.data
            });
        }

        return res.data;
    });
}
import Router from "./Components/router";
import { useEffect } from "react";
import { initialLoad } from "./Store/initial/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./Store";
import { Card } from "antd";
import Header from "./Components/header";



const App = () => {
  const dispatch = useDispatch();
  const isLoaded = useSelector((state: RootState) => state.initial.isLoaded);
  useEffect(() => {
    if (isLoaded) {
      return;
    }
    initialLoad()(dispatch)
  }, [dispatch]);



  return (
    <div>
      {isLoaded ?
        <div>
          <Header />
          <div>
            <Router />
          </div>
        </div>
        :
        <div>Loading...</div>}
    </div>
  );
}

export default App;

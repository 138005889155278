
import { Navigate, Route, Routes } from "react-router-dom";

import Login from "../Pages/login";
import FormQualityFer from "../Pages/formQualityFer";
import ProtectedRouter from "./protectedRouter";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/qualityfer" />}></Route>
      <Route path="/login" element={<Login />} />
      <Route path="/qualityfer" element={<ProtectedRouter><FormQualityFer /></ProtectedRouter>} />
    </Routes>
  );
};

export default Router;

import { api, capi } from "../../../Api";
import { LoginDTOout } from "../../../Api/auth/DTOs/loginDTOout";
import { LOGIN_FAILURE, LOGIN_SUCCESS } from "../constants";
import { getProfileRedux } from "./getProfileRedux";

export const loginRedux = (username: string, password: string) => async (dispatch: any) => {
    return api.auth.login({ username, password }).then((res: LoginDTOout) => {

        if (res.data.success) {
            if (res.data.data.profile.roles.indexOf('cc') > -1) {

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.data
                });
                capi.defaults.headers.common["Authorization"] = `Bearer ${res.data.data.token}`;
                localStorage.setItem("token", res.data.data.token);
                localStorage.setItem("expiresAt", res.data.data.expiresAt);

                dispatch(getProfileRedux());
            } else {
                res.data.success = false;
                res.data.data = {} as any;

                dispatch({
                    type: LOGIN_FAILURE,
                    payload: {
                        message: 'No tiene permisos para acceder a la aplicación'
                    }
                });
            }

        } else {
            dispatch({
                type: LOGIN_FAILURE,
                payload: res.data,
            });
        }
        return res.data;
    }
    ).catch((err: any) => {
        dispatch({
            type: LOGIN_FAILURE,
            payload: err.response.data,
        });
    });
}
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import authReducer, { AuthState } from "./auth/reducer";
import initialReducer, { InitialState } from "./initial/reducer";
import qualityferReducer, { QualityferState } from "./qualityfer/reducer";
import multisearchReducer, { MultisearchState } from "./multisearchOperations/reducer";
import simulateLtvReducer, { SimulateLTVState } from "./scoring/reducer";

export type RootState = {
    auth: AuthState;
    initial: InitialState;
    qualityfer: QualityferState;
    multisearchOperations: MultisearchState;
    simulateLtv: SimulateLTVState;
}

const reducers = combineReducers({
    auth: authReducer,
    initial: initialReducer,
    qualityfer: qualityferReducer,
    multisearchOperations: multisearchReducer,
    simulateLtv: simulateLtvReducer
});

export const store = configureStore({
    reducer: reducers,
});


import { api } from "../../../Api"
import { GET_PROFILE } from "../constants"

//update auth profile redux
export const getProfileRedux = () => async (dispatch: any) => {
    api.auth.getProfile().then((res: any) => {
        dispatch({
            type: GET_PROFILE,
            payload: res.data
        })
    }).catch((err: any) => {
        console.log(err);
    })
}
